import { all, call, put, delay, takeLatest } from "redux-saga/effects";

import API from "./api";

import {
  setPendingFeedbackRequest,
  getFeedbackSuccess,
  getFeedbackFailure,
  postFeedbackSuccess,
  postFeedbackFailure,
  postFeedbackLoader,
} from "./actions";
import { GET_FEEDBACK_REQUEST, POST_FEEDBACK_REQUEST } from "./utils/constants";

function* getFeedback({ researchId }: any) {
  try {
    // const payload = yield call(API.getFeedback, { researchId }); // todo
    yield put(getFeedbackSuccess({ payload: [] }));
  } catch (error) {
    yield put(getFeedbackFailure(error));
  }
}

function* postFeedback({
  name,
  value,
  levelId,
  SOPInstanceUID,
  researchId,
}: any) {
  try {
    yield put(postFeedbackLoader({ payload: true }));
    yield call(API.postFeedback, {
      name,
      value,
      levelId,
      SOPInstanceUID,
      researchId,
    });
    yield put(setPendingFeedbackRequest(null));
    yield put(postFeedbackLoader({ payload: false }));
    yield put(postFeedbackSuccess());
    yield getFeedback({ researchId });
  } catch (error) {
    yield put(setPendingFeedbackRequest(null));
    yield put(postFeedbackLoader({ payload: false }));
    yield put(postFeedbackFailure(error));
  }
}

function* Saga() {
  yield all([takeLatest(GET_FEEDBACK_REQUEST, getFeedback)]);
  yield all([takeLatest(POST_FEEDBACK_REQUEST, postFeedback)]);
}

export default Saga;
